<template lang="pug">
#app
	.input
		fieldset
			legend Simulation
			label
				| {{ duration_minutes }} minutes simulated
				br
				input(type="range", v-model="duration_minutes", min="1", max="240")
		fieldset
			legend Arrival
			label
				| {{ total_arrivals }} total attendees
				br
				input(type="range", v-model="total_arrivals", min="0", max="100000", step="100")
			bunt-select(name="arrival_distribution", label="Arrival distribution", :options="arrival_distributions", v-model="arrival_distribution", option-label="label")
			label(v-if="arrival_distribution != 'gaussian'")
				| {{ arrival_rate_per_hour }} pax/h arrival rate
				br
				input(type="range", v-model="arrival_rate_per_hour", min="1", max="10000")
			label(v-if="arrival_distribution == 'gaussian'")
				| {{ arrival_gauss_width }}% width
				br
				input(type="range", v-model="arrival_gauss_width", min="1", max="50")
		fieldset
			legend Scanning
			label
				| {{ scans_per_scanner_per_hour }} pax/h per scanner
				br
				input(type="range", v-model="scans_per_scanner_per_hour", min="1", max="1000")
			label
				| {{ scanners }} scanners
				br
				input(type="range", v-model="scanners", min="1", max="50")
			bunt-select(name="scan_distribution", label="Scan distribution", :options="scan_distributions", v-model="scan_distribution", option-label="label")
			label(v-if="scan_distribution == 'gaussian'")
				| {{ scan_gauss_width }}% width
				br
				input(type="range", v-model="scan_gauss_width", min="1", max="100")

	.output
		.output-col
			.output-line.graph
				line-chart(:chart-data="arrivalRateChartData", :options="chartOptions")
			.output-line.graph
				line-chart(:chart-data="admissionRateChartData", :options="chartOptions")
			.output-line.graph
				line-chart(:chart-data="admittedChartData", :options="chartOptions")
		.output-col
			.output-line.graph
				line-chart(:chart-data="queueLengthChartData", :options="chartOptions")
			.output-line.graph
				line-chart(:chart-data="waitingTimeChartData", :options="chartOptions")

</template>
<script>
import {simulate} from 'lib/simulation'
import LineChart from 'components/LineChart'

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

export default {
	components: { LineChart },
	data() {
		return {
			duration_minutes: parseInt(params.duration_minutes || 240),
			arrival_distribution: params.arrival_distribution || 'gaussian',
			arrival_rate_per_hour: parseInt(params.arrival_rate_per_hour || 3000),
			arrival_gauss_width: parseInt(params.arrival_gauss_width || 20),
			scans_per_scanner_per_hour: parseInt(params.scans_per_scanner_per_hour || 300),
			scan_distribution: params.scan_distribution || 'gaussian',
			scan_gauss_width: parseInt(params.scan_gauss_width || 10),
			scanners: parseInt(params.scanners || 5),
			total_arrivals: parseInt(params.total_arrivals || 5000),

			arrival_distributions: [
				{id: 'const', label: 'Constant flow per minute'},
				{id: 'poisson', label: 'Poissonian flow per minute'},
				{id: 'gaussian', label: 'Normal distribution over time'},
			],
			scan_distributions: [
				{id: 'const', label: 'Constant flow per minute'},
				{id: 'gaussian', label: 'Gaussian flow per minute'},
			],

			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true
						}
					}]
				},
				layout: {
					padding: {
						top: 0
					}
				}
			}
		}
	},
	computed: {
		result () {
			const obj = {
				duration_minutes: this.duration_minutes,
				arrival_rate_per_hour: this.arrival_rate_per_hour,
				arrival_distribution: this.arrival_distribution,
				arrival_gauss_width: this.arrival_gauss_width,
				scans_per_scanner_per_hour: this.scans_per_scanner_per_hour,
				scan_distribution: this.scan_distribution,
				scan_gauss_width: this.scan_gauss_width,
				scanners: this.scanners,
				total_arrivals: this.total_arrivals,
			}
			history.replaceState(null, '', '/?' + new URLSearchParams(obj).toString())
			return simulate(obj)
		},
		arrivalRateChartData () {
			return {
				labels: this.result.results_by_minute.map((it) => it.minute),
				datasets: [
					{
						label: 'Rate of arrivals per minute',
						borderColor: 'rgba(59,28,74,1)',
						backgroundColor: 'rgba(59,28,74,0.5)',
						pointRadius: 1,
						data: this.result.results_by_minute.map((it) => it.arrival_per_minute)
					}
				]
			}
		},
		queueLengthChartData () {
			return {
				labels: this.result.results_by_minute.map((it) => it.minute),
				datasets: [
					{
						label: 'Number of people waiting in the queue',
						borderColor: 'rgba(59,28,74,1)',
						backgroundColor: 'rgba(59,28,74,0.55)',
						pointRadius: 1,
						data: this.result.results_by_minute.map((it) => it.persons_in_queue)
					}
				]
			}
		},
		admittedChartData () {
			return {
				labels: this.result.results_by_minute.map((it) => it.minute),
				datasets: [
					{
						label: 'Number of people admitted',
						borderColor: 'rgba(59,28,74,1)',
						backgroundColor: 'rgba(59,28,74,0.5)',
						pointRadius: 1,
						data: this.result.results_by_minute.map((it) => it.persons_admitted)
					}
				]
			}
		},
		admissionRateChartData () {
			return {
				labels: this.result.results_by_minute.map((it) => it.minute),
				datasets: [
					{
						label: 'Rate of admissions per minute',
						borderColor: 'rgba(59,28,74,1)',
						backgroundColor: 'rgba(59,28,74,0.5)',
						pointRadius: 1,
						data: this.result.results_by_minute.map((it) => it.admitted_per_minute)
					}
				]
			}
		},
		waitingTimeChartData () {
			return {
				labels: this.result.results_by_minute.map((it) => it.minute),
				datasets: [
					{
						label: 'Waiting time in minutes',
						borderColor: 'rgba(59,28,74,1)',
						backgroundColor: 'rgba(59,28,74,0.5)',
						pointRadius: 1,
						data: this.result.results_by_minute.map((it) => it.waiting_time)
					}
				]
			}
		},
	},
	watch: {},
	mounted() {
	},
	destroyed() {
	},
	methods: {}
}
</script>
<style lang="stylus">
#app
	display: flex
	flex-direction: column
	background: #efefef
	height: 100vh

	.input
		padding: 15px
		display: flex
		flex-direction: row

		fieldset
			flex-basis: 100px
			flex-grow: 1

		label
			display: block

			input[type=range]
				width: 100%

		label + label
			margin-top: 10px

	.output
		flex: 1
		background: white
		overflow-y: auto
		display: flex
		width: 100vw
		flex-direction: row

		.output-col
			display: flex
			flex-direction: column
			flex-grow: 1
			flex-shrink: 1
			flex-basis: 50%
			width: 50%
			height: 100%

			.output-line
				flex-grow: 1
				flex-basis: 100px
				padding: 5px
				height: 100px
				&.graph div
					height: 100%
					width: 100%

</style>
