import Vue from 'vue'
import Buntpapier from 'buntpapier'
import App from './App.vue'
import 'styles/global.styl'
import 'roboto-fontface'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false
Vue.use(Buntpapier)

window.vapp = new Vue({
	render: h => h(App)
}).$mount('#app')

