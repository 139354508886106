import Prob from "prob.js";

const simulate = (
	{
		duration_minutes,
		total_arrivals,
		arrival_rate_per_hour,
		arrival_distribution,
		arrival_gauss_width,
		scan_distribution,
		scan_gauss_width,
		scans_per_scanner_per_hour,
		scanners
	}
) => {
	const results_by_minute = []

	let persons_in_queue = []
	let persons_admitted = []

	let arrival_leftover = 0
	const scan_leftover = {}
	for (let scanner_i = 0; scanner_i < scanners; scanner_i++) {
		scan_leftover[scanner_i] = 0
	}
	for (let minute = 0; minute < duration_minutes; minute++) {
		// Arrival
		let drawn_arrival = arrival_rate_per_hour / 60
		if (arrival_distribution === 'poisson') {
			drawn_arrival = Prob.poisson(arrival_rate_per_hour / 60)()
		} else if (arrival_distribution === 'gaussian') {
			const sigma = duration_minutes / 2 * arrival_gauss_width / 100
			const mu = 3 * sigma
			drawn_arrival = total_arrivals * (
				1 / (sigma * Math.sqrt(2 * Math.PI)) *
				Math.exp(-0.5 * (minute - mu) * (minute - mu) / (sigma * sigma))
			)
		}

		const arrival = Math.min(
			total_arrivals - persons_admitted.length - persons_in_queue.length,
			Math.floor(drawn_arrival + arrival_leftover),
		)
		arrival_leftover += drawn_arrival - arrival
		for (let i = 0; i < arrival; i++) {
			persons_in_queue.push({
				arrival_minute: minute
			})
		}

		// Scanning
		let admit_per_minute = 0
		let waiting_time = 0
		for (let scanner_i = 0; scanner_i < scanners; scanner_i++) {
			let drawn_admit = scans_per_scanner_per_hour / 60
			if (scan_distribution === 'gaussian') {
				drawn_admit = Math.max(0, Prob.normal(drawn_admit, drawn_admit * scan_gauss_width / 100)())
			}
			let admit = Math.floor(drawn_admit + scan_leftover[scanner_i])
			scan_leftover[scanner_i] += drawn_admit - admit
			admit = Math.min(admit, persons_in_queue.length)
			admit_per_minute += admit
			for (let i = 0; i < admit; i++) {
				const head = persons_in_queue.shift()
				waiting_time = Math.max(waiting_time, minute - head.arrival_minute)
				persons_admitted.push(head)
			}
		}

		results_by_minute.push({
			minute,
			waiting_time,
			persons_in_queue: persons_in_queue.length,
			persons_admitted: persons_admitted.length,
			arrival_per_minute: arrival,
			admitted_per_minute: admit_per_minute,
		})
	}


	return {
		results_by_minute,
		duration: duration_minutes
	}
}

export {
	simulate
}
